import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  Alert,
  Avatar,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import useAuth from "../../../../hooks/useAuth";
import axios from "axios";
import { NGROK } from "../../../../APIs";
import UserService, {
  useKeycloakStore,
} from "../../../../services/UserService";
import { useNavigate } from "react-router-dom";
import { myLocalStorage } from "../../../../components/StorageHelper";

import { decodeKeycloakToken } from "../../../../utils/tokenHelpers";
import useUserStore from "../../../../services/userStore";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import OTPAuthentication from "./OTPAuthentication";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { GoPasskeyFill } from "react-icons/go";
import { IoMdUnlock } from "react-icons/io";

const SignUpForm = () => {
  const { signUp } = useAuth();
  const navigate = useNavigate();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [tenantNameErrorMessage, setTenantNameErrorMessage] = useState("");

  const [tenantName, setTenantName] = useState("");
  const [loading, setLoading] = useState(false);
  const [serviceTermsAgreed, setServiceTermsAgreed] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isOTPNeeded, setIsOTPNeeded] = useState(false);
  const [hasOTPError, setHasOTPError] = useState(false);
  const [otpValid, setOTPValid] = useState(false);
  const [otp, setOtp] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [signUpActionForm, setSignUpActionForm] = useState(false);
  const { getUserData: fetchUserData } = useUserStore((state) => state);

  const { local_kc } = useKeycloakStore();

  const isTenantNameFormActive =
    local_kc &&
    (local_kc.realm === "whiteswan_tenants" ||
      local_kc.realm === "whiteswan_tenants_legacy");

  const SignupSchema = Yup.object().shape({
    email: Yup.string().required("Required"),
    tenantName: Yup.string()
      .min(5, "Too Short!")
      .max(50, "Too Long!")
      .required("Required")
      .test(
        "is-lowercase",
        "Only lowercase letters are allowed.",
        (value) => !/[A-Z]/.test(value),
      ),
  });

  const formik = useFormik({
    initialValues: {
      fullName: "",
      tenantName,
      email: "",
      password: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });
  const { tenantName: formikTenantName, email } = formik.values;
  const getTenantsInfo = async (email) => {
    const axiosInstance = axios.create();

    try {
      const tenants = await axiosInstance.get(
        `${NGROK}/api/get-tenants?email=${email.toLowerCase()}`,
      );
      return tenants;
    } catch (error) {
      setTenantName("");
    }
  };
  const onOTPChange = (otp) => {
    setOtp(otp);
  };
  const checkIfUserExist = async (email) => {
    const tenants = await getTenantsInfo(email);

    if (!tenants || !tenants.data?.length) {
      setTenantName("");
      return false;
    } else if (tenants?.data?.length) {
      myLocalStorage.setItem("role", tenants?.data[0].role);
      setFirstName(tenants?.data[0].firstName);
      setLastName(tenants?.data[0].lastName);
      setTenantName(tenants?.data[0].tenantName);
      return tenants?.data[0];
    }
  };

  const handleInputChange = (e) => {
    formik.handleChange(e);
    if (isSubmitted) setIsSubmitted(false);
    setEmailErrorMessage("");
    setTenantNameErrorMessage("");
  };

  const keycloakInitAndRegister = async (realm) => {
    try {
      setLoading(true);
      myLocalStorage.setItem("realm", realm);
      UserService.initKeycloak(realm);

      setTimeout(() => {
        UserService.doRegister(email, firstName, lastName);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const startRegisterViaPasskey = async () => {
    if (!formik.errors.email) {
      setIsSubmitted(true);
      formik.handleSubmit();
      const user = await checkIfUserExist(email);

      if (
        user?.legacyRealm?.realmName === "whiteswan_tenants_initial" ||
        user?.realm?.keycloakAccountExists
      ) {
        setEmailErrorMessage(
          <Typography>User with this email already exists.</Typography>,
        );
        return;
      }

      if (!user) {
        myLocalStorage.setItem("role", "TENANT_ADMIN");
        myLocalStorage.setItem("email", email);
        myLocalStorage.setItem("firstName", firstName);
        myLocalStorage.setItem("LastName", lastName);
        keycloakInitAndRegister("whiteswan_tenants");
      } else if (user && !user.realm.keycloakAccountExists) {
        myLocalStorage.setItem("role", "TENANT_USER");
        myLocalStorage.setItem("email", email);
        myLocalStorage.setItem("firstName", firstName);
        myLocalStorage.setItem("LastName", lastName);
        keycloakInitAndRegister(user.tenantName);
      }
    }
  };

  const startRegisterViaPassword = async () => {
    if (!formik.errors.email) {
      setIsSubmitted(true);
      formik.handleSubmit();
      const user = await checkIfUserExist(email);
      if (
        user?.legacyRealm?.realmName === "whiteswan_tenants_initial" ||
        user?.legacyRealm?.keycloakAccountExists
      ) {
        setEmailErrorMessage(
          <Typography>User with this email already exists.</Typography>,
        );
        return;
      }

      if (!user) {
        myLocalStorage.setItem("role", "TENANT_ADMIN");
        myLocalStorage.setItem("email", email);
        myLocalStorage.setItem("firstName", firstName);
        myLocalStorage.setItem("LastName", lastName);
        keycloakInitAndRegister("whiteswan_tenants_legacy");
      } else if (user && !user.legacyRealm.keycloakAccountExists) {
        myLocalStorage.setItem("role", "TENANT_USER");
        myLocalStorage.setItem("email", email);
        myLocalStorage.setItem("firstName", firstName);
        myLocalStorage.setItem("lastName", lastName);
        keycloakInitAndRegister(`${user.legacyRealm.realmName}`);
      }
    }
  };

  const createTenant = async (local_kc) => {
    setIsSubmitted(true);
    if (formik.errors?.tenantName) return;

    setLoading(true);

    const { data: tenantAlreadyExist } = await axios.get(
      `${NGROK}/api/test-connection?tenantName=${formikTenantName}`,
    );
    if (!tenantAlreadyExist && formikTenantName) {
      const {
        email: userEmail,
        family_name,
        given_name,
      } = decodeKeycloakToken(local_kc.token);

      try {
        const response = await signUp(
          formikTenantName,
          userEmail,
          `${family_name} ${given_name}`,
        );
        fetchUserData(userEmail);

        if (response) {
          navigate("/loading");
        } else {
          setLoading(false);
        }
      } catch (error) {
        setTenantNameErrorMessage(
          "Something went wrong during test connection.",
        );
        console.error(error, "sign up error");
      }
    }
  };

  const handleCheckbox = () => {
    setServiceTermsAgreed(!serviceTermsAgreed);
  };
  console.log(formik.errors, "errors");

  const verifyTheEmail = async () => {
    const errors = await formik.validateForm();
    setSubmitLoading(true);
    if (!errors?.email) {
      formik.handleSubmit();
      const user = await checkIfUserExist(email.trim());
      if (!user) {
        setIsOTPNeeded(false);
        setSignUpActionForm(true);
      } else {
        if (
          user?.realm?.keycloakAccountExists ||
          user?.legacyRealm?.keycloakAccountExists
        ) {
          setSubmitLoading(false);
          setIsOTPNeeded(false);
          setSignUpActionForm(false);
          setEmailErrorMessage(
            <Typography>User with this email already exists.</Typography>,
          );
          return;
        } else if (user.isVerificationRequired) {
          setIsOTPNeeded(true);
          setSignUpActionForm(false);
        } else {
          setIsOTPNeeded(false);
          setSignUpActionForm(true);
        }
      }
      setSubmitLoading(false);
      setIsSubmitted(true);
    } else {
      setIsSubmitted(false);
      setIsOTPNeeded(false);
    }
  };

  const verifyEmailInvite = useCallback(
    async (otp) => {
      const axiosInstance = axios.create();
      let params = {
        email: email.toLowerCase(),
        verificationCode: parseInt(otp),
      };

      try {
        let { data } = await axiosInstance.post(
          `${NGROK}/api/invite/validate`,
          params,
        );
        if (!data.isOk) {
          // Incorrect OTP.
          setHasOTPError(true);
          setOTPValid(false);
          setSignUpActionForm(false);
        } else {
          setHasOTPError(false);
          setOTPValid(true);
          setSignUpActionForm(true);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [email],
  );

  const passVerification = () => {
    setHasOTPError(false);
    setIsOTPNeeded(false);
    createTenant(local_kc);
  };
  const moveBack = () => {
    setIsSubmitted(false);
    setHasOTPError(false);
    setOTPValid(false);
    setIsOTPNeeded(false);
    setSignUpActionForm(false);
  };

  const stringToColor = (name) => {
    let hash = 0;
    let i;

    for (i = 0; i < name.length; i += 1) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const textAvatar = (name) => {
    let initials = "";
    const nameParts = name.split(" ");

    if (nameParts.length >= 2) {
      initials = `${nameParts[0][0]}${nameParts[1][0]}`;
    } else if (name.includes("@")) {
      const emailParts = name.split("@")[0].split(".");
      if (emailParts.length >= 2) {
        initials = `${emailParts[0][0]}${emailParts[1][0]}`;
      } else {
        initials = `${name[0]}${name[1]}`;
      }
    } else {
      initials = `${name[0]}${name[1]}`;
    }

    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: initials.toUpperCase(),
    };
  };

  const realm = myLocalStorage.getItem("realm");

  const access_token =
    UserService?.getToken() || myLocalStorage.getItem("access_token");
  useEffect(() => {
    if (!access_token && realm) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 4000);
    }
  }, [access_token, realm]);

  if (loading)
    return (
      <Box
        display={"flex"}
        p={5}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{
          maxHeight: 400,
          minHeight: 400,
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <>
      <Stack
        spacing={2}
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            mb: "50px !important",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          {isSubmitted && (
            <IconButton
              sx={{ position: "absolute", left: 0 }}
              onClick={moveBack}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
          >
            <LockOutlinedIcon
              sx={{
                color: "red",
                fontSize: "2rem",
              }}
            />
            <Typography
              component="h1"
              variant="h1"
              sx={{
                wordBreak: "break-word",
                fontSize: "24px",
                fontWeight: "bold",
                letterSpacing: ".011em",
                lineHeight: "normal",
              }}
            >
              {isTenantNameFormActive ? "Setup Tenant Name" : "Sign up"}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: 3, width: "100%" }}>
          <Grid container spacing={2}>
            {!isOTPNeeded && !signUpActionForm ? (
              isTenantNameFormActive ? (
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="tenantName"
                    label="Tenant Name"
                    name="tenantName"
                    autoComplete="family-name"
                    onChange={(e) => handleInputChange(e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        passVerification(e);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {submitLoading ? (
                            <CircularProgress />
                          ) : (
                            <IconButton
                              variant="contained"
                              onClick={passVerification}
                              sx={{
                                borderRadius: "50%",
                              }}
                            >
                              <ArrowForwardIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.errors?.tenantName && isSubmitted ? (
                    <Alert severity="error">{formik.errors.tenantName}</Alert>
                  ) : null}
                  {tenantNameErrorMessage && isSubmitted ? (
                    <Alert severity="error">{tenantNameErrorMessage}</Alert>
                  ) : null}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoFocus
                    autoComplete="email"
                    onChange={(e) => handleInputChange(e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        verifyTheEmail(e);
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {submitLoading ? (
                            <CircularProgress />
                          ) : (
                            <IconButton
                              variant="contained"
                              onClick={verifyTheEmail}
                              sx={{
                                borderRadius: "50%",
                              }}
                            >
                              <ArrowForwardIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.errors.email && isSubmitted ? (
                    <Alert sx={{ marginTop: 1 }} severity="error">
                      {formik.errors.email}
                    </Alert>
                  ) : null}
                  {!formik.errors?.email && emailErrorMessage ? (
                    <Alert sx={{ marginTop: 1 }} severity="error">
                      {emailErrorMessage}
                    </Alert>
                  ) : null}
                </Grid>
              )
            ) : null}
          </Grid>
          {isOTPNeeded && !signUpActionForm && (
            <OTPAuthentication
              verifyEmailInvite={verifyEmailInvite}
              hasOTPError={hasOTPError}
              setHasOTPError={setHasOTPError}
              onOTPChange={onOTPChange}
              otp={otp}
            />
          )}
          {signUpActionForm ? (
            <>
              <Box sx={{ marginTop: "-35px !important", mb: 1 }}>
                <Stack
                  direction={"column"}
                  spacing={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Avatar {...textAvatar(email)} />
                  <Typography variant="body1">
                    Hello, {lastName},{firstName}
                  </Typography>
                  {!isTenantNameFormActive && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button
                        onClick={() => startRegisterViaPasskey()}
                        disabled={!serviceTermsAgreed || !email}
                        variant="outlined"
                        color="primary"
                        startIcon={<GoPasskeyFill />}
                        sx={{
                          mb: 1,
                          py: 1,
                        }}
                      >
                        Passkey
                      </Button>
                      <Typography
                        align="center"
                        variant="subtitle1"
                        fontWeight={400}
                        sx={{ mb: 1 }}
                      >
                        OR
                      </Typography>
                      <Button
                        onClick={() => startRegisterViaPassword()}
                        disabled={!serviceTermsAgreed || !email}
                        variant="outlined"
                        color="primary"
                        sx={{
                          py: 1,
                        }}
                        startIcon={<IoMdUnlock />}
                      >
                        Password & OTP
                      </Button>
                    </div>
                  )}
                </Stack>
              </Box>
            </>
          ) : null}
        </Box>
      </Stack>
      {signUpActionForm && (
        <Grid container justifyContent="space-between">
          {!isTenantNameFormActive ? (
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={serviceTermsAgreed}
                    onChange={handleCheckbox}
                    color="primary"
                  />
                }
                label={
                  <Typography>
                    I agree to the{" "}
                    <Link href="/terms_of_service">Terms and Conditions</Link>
                  </Typography>
                }
                labelPlacement="end"
              />
            </Grid>
          ) : null}
        </Grid>
      )}
      <Grid container justifyContent="center">
        <Grid item>
          <Typography variant="body1">
            Already have an account?{" "}
            <Link href="/signIn" variant="body2">
              Sign in
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default SignUpForm;
